import React, { useRef, useState, useEffect } from 'react';

import styled from "styled-components";
import tw from "twin.macro";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
//import CloseButton from 'react-bootstrap/CloseButton';
import HorizontalLinearAlternativeLabelStepper from 'pages/HorizontalLinearAlternativeLabelStepper';
import Cookies from 'js-cookie';

//import InputLabel from '@mui/material/InputLabel';
//import MenuItem from '@mui/material/MenuItem';
//import FormControl from '@mui/material/FormControl';
//import Select, { SelectChangeEvent } from '@mui/material/Select';
//import { loadStripe } from "@stripe/stripe-js";
//eslint-disable-next-line
import {RadioButton, RadioGroupButton, RadioGroup} from 'react-radio-buttons';
import { css } from "styled-components/macro";
import './dialogstyle.css';
//import AutoComplete from './Autocomplete.js';
//import Login from "./Dialog.js";
import {loadStripe} from '@stripe/stripe-js';
import 'react-phone-number-input/style.css';
//import google from "react-google-autocomplete";
//import CardIcon from "../images/credit-card.svg";
//import ProductImage from "../images/product-image.jpg";
//import ProductImage from "../images/apple-icon.png";




 //import Pricing from "components/pricing/ThreePlans.js";
 import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
//import DesignIllustration from "../../images/design-illustration-2.svg";
//import DesignIllustration from "../../images/demo/VY9U4343.jpg";

//import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
//import ReactGoogleAutocomplete from 'react-google-autocomplete';
import img from '../../images/demo/0909_CARROLL_COUNTY.png';
//import { useForceUpdate } from 'framer-motion';
//const Container = tw.div`relative`;
//const Container = tw.div`items-center`;
//"url(" + Background + ")"
export const LogoLink = styled.div`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;
// eslint-disable-next-line
const defaultLogoLink = (
  <LogoLink href="/">
    <img src={img} alt="logo" /> </LogoLink>
);
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: "url(" + ${img}  + ")";//url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
//const LeftColumn = tw.div`relative xl:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const LeftColumn = tw.div`relative flex-1 flex flex-col justify-center items-center sm:ml-12`;
//const LeftColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end items-center`;

const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end items-center`;
//const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;
let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }

  return stripePromise;
};

const Heading = tw.h1`ml-8 mr-8 lg:ml-0 lg:mr-0 font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`ml-8 mr-8 lg:ml-0 lg:mr-0 my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const CustomStyle = {
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
    color: state.isSelected ? '#000000' : '#000000',
    ':active': {
      backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
      color: state.isSelected ? '#000000' : '#000000',
      
    }
  })
};

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;
const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .price1 {
    ${tw`font-bold text-sm my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;

//const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;


const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;

const defaultPlans = [
  /* {
     name: "Personal",
     price: "$17.99",
     duration: "Monthly",
     mainFeature: "Suited for Personal Blogs",
     features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"],
   },*/
   {
     name: "Price",
     //price: //{this.props.price},//"$37.99",
     duration: "One Time Fee",
     mainFeature: "This Price Includes:",
     features: ["Lights Install and Take down", "Preferred Notifications for Lights Installs", ""],
     featured: true,
   },
   /*{
     name: "Enterprise",
     price: "$57.99",
     duration: "Monthly",
     mainFeature: "Suited for Big Companies",
     features: ["90 Templates", "9 Landing Pages", "37 Internal Pages", "Personal Assistance"],
   },*/
 ];

var plans = defaultPlans;

const highlightGradientsCss = [
  css`
    background: rgb(56, 178, 172);
    background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
  `,
  css`
    background: rgb(56, 178, 172);
    background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
  `,
  css`
    background: rgb(245, 101, 101);
    background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
  `
];




const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  };

  
  ${props =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;





 function SomeColumns()  {


  

  const options1 = [
    { value: "Email", label: "Email" },
    { value: "Phone Call", label: "Phone Call" },
    { value: "Text Message", label: "Text Message" },
  ];
  const options2 = [
    { value: "Credit Card", label: "Credit Card" },
    { value: "Cash", label: "Cash" },
    { value: "Check", label: "Check" },
  ];

  const navigate = useNavigate();

  //const [value, setValue] = useState('')
  //const [cashOrCheck, setCashOrCheck] = useState(false)
// eslint-disable-next-line
 const [isShow, invokeModal] = useState(false);

 const [name, setName] = useState('');
 const [nameError, setNameError] = useState('');

 const [phone, setPhone] = useState('');
 const [phoneError, setPhoneError] = useState('');
 const [notification, setNotification] = useState("Email");
 const [payment, setPayment] = useState('Credit Card');
 const handleNameChange = (event) => {
  if (event.target.value === '' || event.target.value.length < 6) {
       setNameError('Name needs to be a minimum of 6 characters');
       setNameSuccessful(false);
  }
       else
       {
              setName(event.target.value);
              setNameError('');
              setNameSuccessful(true);
       }
         //setPhone(event.target.value);


};

const redirectToCashCheckout = async () => {
  var customer = 0;
  fetchCustomer().then(cust => {
      customer = cust;
     // redirectToCheckout();
  //console.log(cust.customerIDdata); // fetched movies
}).then(async function() {
  //    successUrl: `${window.location.origin}/success/{CHECKOUT_SESSION_ID}/${data.houseID}/${customer}/${email}`,
     navigate(`/success/${payment}/${data.houseID}/${customer}/${accountTokenData.email}/0`);
     
})
};

const redirectToCheckout = async () => {
  var customer = 0;
  fetchCustomer().then(cust => {
      customer = cust;
     // redirectToCheckout();
  //console.log(cust.customerIDdata); // fetched movies
}).then(async function() {
  const checkoutOptions = {
    lineItems: items,
//lineItems: [{tax_rates: "txr_1Nw8HYJReSkMWeKDjBjRaIQr"}],
    mode: "payment",
    successUrl: `${window.location.origin}/success/{CHECKOUT_SESSION_ID}/${data.houseID}/${customer}/${email}/1`,
    cancelUrl: `${window.location.origin}/cancel`,
    
  };

    setLoading(true);

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);
    setLoading(false);

    if (stripeError) alert(stripeError);
    // handle the response
  });
   

  };

const handleNotificationChange = (event) => {
    setNotification(event.value);
};

const handlePaymentChange = (event) => {
    setPayment(event.value);
};
   const handleEmailChange = (event) => {
     // eslint-disable-next-line
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (!emailRegex.test(email)) {
    setEmailError('Please enter a valid email address');
    setEmailSuccessful(false);
  } else {
    setEmailError('');
    setEmailSuccessful(true);
  }
setEmail(event.target.value);
};
const handlePhoneChange = (event) => {
  const re = /^[0-9\b]+$/;

      if (re.test(event.target.value)) {
         setPhone(event.target.value);

         if (event.target.value.length < 10)
  {
      setPhoneError('Phone Number doesn\'t equal 10 digits');
      setPhoneSuccessful(false);
  }
      else{
        setPhoneSuccessful(true);
        setPhoneError('');
      }
      }

   
      
};

const handleCheckBox = (event) => {
     // eslint-disable-next-line

//setCheckedError('');
setAckChecked(event.target.checked);
if (event.target.checked)
setCheckedError('');

};

     // eslint-disable-next-line
const handleRadioGroup = (event) => {
     // eslint-disable-next-line
     // eslint-disable-next-line
setCheckedError('');
     // eslint-disable-next-line
setAckChecked(event.checked);
};

// eslint-disable-next-line
function formHandler(e)
{
  // eslint-disable-next-line
  let item = e.target.value;
  //if (item.length < 4)
 // {
 //   
 // }
  e.preventDefault()
};

  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [items, setitems] = useState([]);
  /*const item = {[
    price: "price_1NkccZJReSkMWeKDtksA4USp",
    quantity: 1],
  };*/


  


  //let [page, setPage] = useState('page1');
  const [data, setData] = useState(0);
  const [accountTokenData, setAccountTokenData] = useState(0);


  //const [data2, setData2] = useState(0);
  // eslint-disable-next-line
  function handleClick(place) {
   // console.log(place.address_components[0]);
    //alert('0: ' + place.address_components[0].long_name);
    //alert('1: ' + place.address_components[1].long_name);
    //alert('2: ' + place.address_components[2].long_name);
    //alert('3: ' + place.address_components[3].long_name);
    //alert('4: ' + place.address_components[4].long_name);
    //alert('5: ' + place.address_components[5].long_name);
    //alert('6: ' + place.address_components[6].long_name);
    //alert('7: ' + place.address_components[7].long_name);11111111111
    const xhr = new XMLHttpRequest();
    //const [ipAddress, setIPAddress] = useState('');
    //const blockedIPS = ['104.222.82.9', '174.215.247.10','208.126.160.130']
    //xhr.setRequestHeader("Content-Type", "application/json");
    //xhr.open('GET', 'https://prod.carrollcountychristmaslights.com/api/House?Address=' + place.formatted_address); 
    if (place.address_components !== undefined & !isNaN(+place.address_components[0].long_name))
    {
      
          xhr.open('GET', 'https://prod.carrollcountychristmaslights.com/api/House?Address=' + place.formatted_address); 
    //xhr.open('GET', 'https://localhost:7080/api/House?Address=' + place.formatted_address); 
   
    //xhr.open('GET', 'https://localhost:7080/api/House?Number=' + place.address_components[0].long_name + '&Street=' + place.address_components[1].long_name + '&Town=' + place.address_components[2].long_name + '&County=' + place.address_components[3].long_name + '&State=' + place.address_components[4].long_name + '&Zip=' + place.address_components[5].long_name);
   // xhr.open('GET', 'https://api.example.com/data');
      xhr.onload = function() {
      if (xhr.status === 200) {
        //console.log(xhr.responseText);
        var testResponse = JSON.parse(xhr.responseText);
      
        if (testResponse.address === "NOT FOUND")
        {
          setData(0);
          setLoading(true);
        alert('Address not found, if this address is valid please send an email with the address to carrollcountychristmaslights@gmail.com to get your estimate.');
        return;
        }
        else if (testResponse.address === "NO FLOORS")
        {
          setData(0);
          setLoading(true);
          alert('Some of the data points required in creating this quote are missing. Send an email with the address to carrollcountychristmaslights@gmail.com to get your estimate.');
          return;
        }
        else if (testResponse.address === "NO SQUAREFOOTAGE")
        {
          setData(0);
          setLoading(true);
          alert('Some of the data points required in creating this quote are missing. Send an email with the address to carrollcountychristmaslights@gmail.com to get your estimate.');
          return;
        }
        else if (testResponse.address === "BLOCKED")
        {
          setData(0);
          setLoading(true);
          alert('Hello Competition, its a very clever move using our site to look up all your customers to get an idea on what to price.  A little backstory here: we built the front end to back end.  Long story short we see every request that comes in and the location its coming from.  It even goes all the way to telling us the iphone 12 users name on the phone.  Crazy, right?  Now, we cant continue to let you use our service because it takes resources away from our actual true customers.  How do we move forward?  Email me, ill send over a link for you to pay $20 a month to continue to look up houses.  I really will allow it, because I do feel like theres a enough business in carroll county to share with you guys.  Remember, anything on the web is always tracked with much more detail than you probably want to give (wink)');
          return;
        }
        else
        {
        setData(JSON.parse(xhr.responseText));
        setLoading(false);
        }
       // console.log(xhr.responseText);
        // price: "price_1NkccZJReSkMWeKDtksA4USp",
    //quantity: 1],
        //if (data.square)
        //squareFootage
        //floorCount
       
        var json = JSON.parse(xhr.responseText);
       // console.log(json);
  //      var stdate = '10/05/2023 18:00:00';
   //var enddate = '10/08/2023 20:00:00';
      // var GivenDate = '2018-02-22 ';



/*const dt = new Date();
const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
var newDate = `${
    padL(dt.getMonth()+1)}/${
    padL(dt.getDate())}/${
    dt.getFullYear()} ${
    padL(dt.getHours())}:${
    padL(dt.getMinutes())}:${
    padL(dt.getSeconds())}`;*/
  //  console.log(newDate);

//GivenDate = new Date(GivenDate);
//console.log(stdate);
//console.log(enddate);

//console.log('first part:' + place.address_components[0].long_name + 'and here is the second part:' + place.address_components[1].long_name)
//if(stdate < newDate && newDate < enddate){
  if (place.address_components[0].long_name === "426" && place.address_components[1].long_name === "West 21st Street")
       {
        console.log('here');
        setitems([{price: "price_1Nxg51JReSkMWeKDDjzndIPf", quantity: 1}]); //prod
       }
       else if (place.address_components[0].long_name === "1912" && place.address_components[1].long_name === "Joan Avenue")
       {
        setitems([{price: "price_1NwEyfJReSkMWeKDM9kWXVLs", quantity: 1}]); //prod
       }
        else if (json.squareFootage < 1000 & json.floorCount === 1)
        {
         //test  setitems([{price: "price_1NthiHJReSkMWeKDzV1317xq", quantity: 1}]);
        // setitems([{price: "price_1Nv8mCJReSkMWeKDk9O2S4H6", quantity: 1}]); //prod
        setitems([{price: "price_1Nxg51JReSkMWeKDDjzndIPf", quantity: 1}]); //prod
        }
        else if (json.squareFootage < 1000 & json.floorCount > 1)
        {
          setitems([{price: "price_1Nxg6rJReSkMWeKD4foym6Ad", quantity: 1}]);
        }
        else if (json.squareFootage < 1250 & json.floorCount === 1)
        {
          setitems([{price: "price_1Nxg91JReSkMWeKDxdz4UY4y", quantity: 1}]);
        }
        else if (json.squareFootage < 1250 & json.floorCount > 1)
        {
          setitems([{price: "price_1NxgC6JReSkMWeKDP4szQHHc", quantity: 1}]);
        }
        else if (json.squareFootage < 1500 & json.floorCount === 1)
        {
          setitems([{price: "price_1NxgDlJReSkMWeKDMilXZTrS", quantity: 1}]);
        }
        else if (json.squareFootage < 1500 & json.floorCount > 1)
        {
          setitems([{price: "price_1NxgFmJReSkMWeKD3oQLeR1o", quantity: 1}]);
        }
        else if (json.squareFootage < 1750 & json.floorCount === 1)
        {
          setitems([{price: "price_1NxgHiJReSkMWeKDtNPc1R6o", quantity: 1}]);
        }
        else if (json.squareFootage < 1750 & json.floorCount > 1)
        {
          setitems([{price: "price_1NxgMgJReSkMWeKDZUHIfCmH", quantity: 1}]);
        }
        else if (json.squareFootage > 1750 & json.floorCount === 1)
        {
        setitems([{price: "price_1NxgP8JReSkMWeKDOpqbsQor", quantity: 1}]);
        }
        else if (json.squareFootage > 1750 & json.floorCount > 1)
        {
        setitems([{price: "price_1NxgRnJReSkMWeKDWQUrLgOw", quantity: 1}]);
        }
  
      }
    };
    xhr.send();
  }
  else{
    setData(0);
          setLoading(true);
    alert('Please select a valid residential home address. If you think this is a valid address, contact us at carrollcountychristmaslights@gmail.com');
  }
  };

 

  async function fetchCustomer() {
  //const response = await  fetch('https://prod.carrollcountychristmaslights.com/api/Customer',{
  const response = await  fetch('https://prod.carrollcountychristmaslights.com/api/Customer',{
      method: 'POST',
      headers:{'Content-Type':'application/json'},
        body: JSON.stringify({ houseID: data.houseID, name: accountTokenData.name,email: accountTokenData.email ,payment:payment, notification: notification, phone: phone })
    });
  const customerIDdata = await response.json();
  return customerIDdata;
}



  function submitCustomer() {
      //setOrderDate(new Date());
   // console.log("this is it" , place);
    //console.log(payment);
    //console.log(notification);
if (payment === "Cash" || payment === "Check")
{
redirectToCashCheckout();
}
else{
redirectToCheckout();
}
    /*var xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://localhost:7080/api/Customer');
    xhr.setRequestHeader("accept", "text/plain");
    xhr.setRequestHeader("Content-Type", "application/json");
    //xhr.send(JSON.stringify({ name: name,email: email,payment:payment }));

    xhr.onload = function() {
      if (xhr.status === 200) {
        setData(JSON.parse(xhr.responseText));
        console.log(xhr.responseText);
      }
    };
    xhr.send(JSON.stringify({ name: name,email: email,payment:payment }));*/

   



    
   /* fetch('https://localhost:7080/api/Customer',{
      method: 'POST',
      headers:{'Content-Type':'application/json'},
        body: JSON.stringify({ houseID: data.houseID, name: name,email: email,payment:payment, notification: notification, phone: phone })
    }).then(response => response.json()).then(console.log('this is what we are getting ' + data))
        .then(data => setCustID(data.id)).then(redirectToCheckout());*/
        //setCustID(res);
        //console.log(res);
       // redirectToCheckout();
      
  };
    
  //redirectToCheckout();
  //const [username, setUsername] = useState('')
  //const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [emailSuccesful, setEmailSuccessful] = useState(false)
  const [nameSuccessful, setNameSuccessful] = useState(false)
  //const [custID, setCustID] = useState(0);
  const [phoneSuccessful, setPhoneSuccessful] = useState(false)
  const [ackChecked, setAckChecked] = useState(false)
  //const [orderDate, setOrderDate] = useState()

  //const [ackCheckedError, setAckCheckedError] = useState('')
  const [checkedError, setCheckedError] = useState('')


  function handleSubmit(e) {
      e.preventDefault()
     // console.log(e);
      //if (cashOrCheck)
      //console.log('we made it here');
      //else
      if (!ackChecked)
      {
    setCheckedError('Please read through the lines above and check the agreement box when completed in order to continue to checkout');
      }
    else{
      if (ackChecked  & phoneSuccessful)
      //if (ackChecked & nameSuccessful & emailSuccesful & phoneSuccessful)
      {
        togglePop();
          submitCustomer();
         // console.log(blah);
      }
      else{    
        return;
      }
    }
    
     


      // Code to handle login goes here
     // props.toggle()
  }
  // eslint-disable-next-line
  const autoCompleteRef = useRef();
 const inputRef = useRef();
 // eslint-disable-next-line
 const options = {
    bounds: {
        north: 42.210538,
        south: 41.863753,
        east: -94.628818,
        west: -95.091617,
      },
    componentRestrictions: { country: "us" },
    //fields: ["address_components", "geometry", "icon", "name"],
    fields: ["ALL"],
    //types: ["establishment"],
    placeholder: "blah blah blah",

    strictBounds: true
   };
   const [seen, setSeen] = useState(false)

function togglePop () {
    setSeen(!seen);
};

function exitClicked () {
    setSeen(false);
};
//xhr.open('GET', 'https://localhost:7080/api/House?Address=' + place.formatted_address); 

useEffect(() => {
  var cookie = Cookies.get('CCCL');
 // fetch("https://localhost:7080/api/Account/Retrieve?Token=" + cookie)
  fetch("https://prod.carrollcountychristmaslights.com/api/Account/Retrieve?Token=" + cookie)
  .then(response => response.json())
  .then(data => setAccountTokenData(data))

},[]);


function timeout(number) {
  console.log('here timeout');
  return new Promise( res => setTimeout(res, number) );
}
console.log(accountTokenData);
// eslint-disable-next-line
 useEffect(() => {
  autoCompleteRef.current = new window['google'].maps.places.Autocomplete(
   inputRef.current,
   options
  );
  autoCompleteRef.current.addListener("place_changed", async function () {
   const place = await autoCompleteRef.current.getPlace();
   //console.log({ place });
   handleClick(place);
  });
 }, 
 // eslint-disable-next-line
[]);

  return (
    <>
      <Header/>
      
      <Container>
      {accountTokenData.orderDate != null ?  
      <div><br/><br/><br/><br/><br/><br/><br/>
<Heading>
            Welcome back, <span tw="text-primary-500">{accountTokenData.name}.</span>
            <br/><br/>
            </Heading> 
            <Paragraph>
              Let's check out your order status:
            </Paragraph>
                 <HorizontalLinearAlternativeLabelStepper payment= {accountTokenData.paymentMethod} orderdate={accountTokenData.orderDate} installdate={accountTokenData.installDate} removaldate ={accountTokenData.removalDate}/></div> : <br/>}
           {accountTokenData.orderDate == null ? <TwoColumn>

          <LeftColumn>
          
            <Heading>
            Hello <span tw="text-primary-500">{accountTokenData.name}.</span>
            </Heading>
            <Paragraph>
              Search for the address you would like your christmas lights 
              installed at in search below:
            </Paragraph>
            <Actions>
            <div>
   <label>enter address :</label>
   <input ref={inputRef} />
  </div>


            </Actions>
          </LeftColumn>
          <RightColumn>

          <PlansContainer>
         
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && <div className="planHighlight" css={highlightGradientsCss[index % highlightGradientsCss.length]} />}
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="price">
      {data ? <div>${JSON.stringify(data.totalPriceBeforeTaxes)}</div> : <div>......
          <p className="price1">price calculated after search</p></div>}
    </span>
                <span className="duration">{plan.duration}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
                <div>

                <BuyNowButton  onClick={togglePop}
      disabled={isLoading}>Buy Now </BuyNowButton>
        {seen ?  <div className="popup" align-content="center" align="center">
          <div className="popup-inner">
          <button style={{
          fontWeight: 'bold',
          backgroundColor: '#f44336', /* Green */
  border: 'none',
  color: 'white',
  padding: '15px 32px',
  alignContent: 'right',
  display: 'inline-block',
  fontSize: '16px',
  margin: '4px 2px',
  cursor: 'pointer',
  float: 'right'
        }} onClick={exitClicked}
>Exit</button>
<br/><br/>
                        <h2>Gathering Info</h2>
              <form onSubmit={handleSubmit}>

                <label>
                Enter Contacts Full Name
                  <input
                  placeholder='Enter Contacts Full Name' 
        type="text" 
        id={name}
        value={accountTokenData.name}
        disabled={true}
        onChange={(e) => handleNameChange(e)}
      ></input> <br /></label>
      <span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{nameError}</span>
      <label>Select Preferred Notification</label>
      <Select styles={CustomStyle} options={options1}         defaultValue={{ label: "Email", value: "Email" }}         onChange={(e) => handleNotificationChange(e)} />

      <br/>
                  <label>
                  Enter Email
                  <input
                  placeholder='Enter Email' 
        type="text" 
        id={email}
        value={accountTokenData.email}
        disabled={true}
        onChange={(e) => handleEmailChange(e)}
      ></input> <br /></label>
      <span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{emailError}</span>
        <label>
        Enter Phone number(10 digits, only numbers)
        <input value={phone} onChange={handlePhoneChange} maxLength={10} placeholder='Enter Phone Number'/></label>
        <span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{phoneError}</span>

        <label>Select Preferred Payment Method</label>
      <Select styles={CustomStyle} options={options2}         defaultValue={{ label: "Credit Card", value: "Credit Card" }} onChange={(e) => handlePaymentChange(e)} />

        <label>
        Acknowledge
        <span>
        <input
        type="checkbox" style={{
          display: "inline-block",
         verticalAlign: "middle",
         cursor: "pointer"
          
        }}
        onChange={(e) => handleCheckBox(e)}
              checked={ackChecked}
        ></input>
        </span>
        <p>Please read through the below agreements, when completed and once you agree, click the acknowlege checkbox above to continue.</p>
        <ol>
        {payment === 'Credit Card' ? <li className='dialog'>Paying the one time price at checkout of {data.totalPriceBeforeTaxes} + tax</li> : <li className='dialog'>Paying the one time price at time of service due to choosing cash/check</li>}
	<li className='dialog'>The price you pay is a one time fee which includes put up and take down</li>
	<li className='dialog'>Having the lights and clips ready to go on the date of installation</li>
	<li className='dialog'>There is no set date due to weather, as we get closer to November you will be notified of your official scheduled date</li>
	<li className='dialog'>Agree to phone/email notifications as the date gets closer based on the preferred notification</li>
	<li className='dialog'>Agreeing to Carroll County Christmas Lights putting up and taking down christmas lights</li>
  <li className='dialog'>Under no circumstances shall carroll county christmas lights be liable for any damages during the lights install and take down.</li>

</ol>
        </label>
        <span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{checkedError}<br/></span>
                  <button type="submit">Continue To Checkout</button>
              </form>
          </div>
      </div>
  : null}
        </div>
              </PlanFeatures>
            </Plan>
          ))}
          <DecoratorBlob/>
        </PlansContainer>
          </RightColumn>
        </TwoColumn> : <br/>}
        <DecoratorBlob1 />
      </Container>
    </>
  )
          };
      
          export default SomeColumns;


